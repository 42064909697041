import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import Typography from "@material-ui/core/Typography";
//import dateHelper from "common/utils/dateHelper";

class WorksheetCosts extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("user-costs");
    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes["user-costs"],
        id: "worksheet-costs",
        url: "user-costs",
        condition: {
          id: "worksheet_id",
          value: this.props.item["id"]
        },
        columns: [
          /*{
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            }
          },*/
          /*{
            id: "day",
            type: "unixDate",
            defaultValue: new Date().getTime() / 1000
          },*/
          {
            id: "cost_id",
            type: "select",
            datasource: this.props.item.project.custom_costs
              ? {
                controller: "project-costs",
                objectField: "cost",
                valueField: "cost.id",
                search: ["cost.name"],
                filter: [
                  ["cost.show_at_worksheet", "exact", 1],
                  ["cost.min_role", "gte", user.data.role],
                  ["cost.active", "exact", true],
                  ["project_cost.active", "exact", true],
                  ["project_id", "exact", this.props.item["project_id"]]
                ],
                sort: { "cost.name": "asc" },
                label: item => {
                  return item ? item.name : "";
                }
              }
              : {
                controller: "costs",
                objectField: "cost",
                valueField: "id",
                search: ["cost.name"],
                filter: [
                  ["show_at_worksheet", "exact", true],
                  ["show_only_at_project", "exact", false],
                  ["min_role", "gte", user.data.role]
                ],
                sort: { "cost.name": "asc" },
                label: (item, isCell) => {
                  if (isCell && item) {
                    return item.name;
                  }
                  return (
                    item && (
                      <div>
                        {item.name}
                        {item.description && (
                          <Typography
                            component="div"
                            variant="inherit"
                            className="description"
                            style={{
                              fontSize: 11,
                              marginTop: -4,
                              lineHeight: "13px",
                              whiteSpace: "normal"
                            }}
                          >
                            {item.description}
                          </Typography>
                        )}
                      </div>
                    )
                  );
                }
              }
          },
          { id: "amount", type: "currency", footer: "sum" },
          /*{
            id: "person_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "person",
              valueField: "id",
              search: ["user.name"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            },
            hidden: item => {
              if (item.cost && item.cost.resource === 2) {
                return false;
              }
              return true;
            },
            label: item => {
              if (item.cost && item.cost.incoming === true) {
                return "Kitől";
              }
              return "Kinek";
            }
          },*/
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["platenumber"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.platenumber : "";
              }
            },
            /*visible: item => {
              return false;
            },*/
            hidden: item => {
              if (item.cost && item.cost.resource === 1) {
                return false;
              }
              return true;
            }
          },
          {
            id: "counter",
            hidden: item => {
              if (item.cost && item.cost.counter_required) {
                return false;
              }
              return true;
            }
          },
          {
            id: "amount_count",
            hidden: item => {
              if (item.cost && item.cost.amount_count_required) {
                return false;
              }
              return true;
            }
          },
          { id: "description" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access } = this.state;
    const {
      /*classes,*/ user,
      item,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;

    return (
      <div>
        <DataGrid
          user={user}
          isDetailView={isDetailView}
          onEdit={onChildEdit}
          onCancelEdit={onChildCancelEdit}
          breadcrumbs={breadcrumbs}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            const access = user.accessField("user-costs", column.id);
            if (access) {
              if (item && item.status > 1) {
                return "r";
              }
            }
            return access;
          }}
          access={row => {
            if (
              (item && item.status > 1) ||
              (row && row.accepted) ||
              (row && row.last_open > row.day)
            ) {
              return "r";
            }
            return access;
          }}
          filterable={false}
          showPaginationBottom={false}
          defaultPageSize={500}
          defaultPageSizeFullHeight={500}
          title={row => {
            if (!row) {
              return "Tallózás";
            } else if (row.id) {
              return row.cost.name;
            } else {
              return `Új ${strings.menu["user-costs"].singularTitle}`;
            }
          }}
        />
      </div>
    );
  }
}

WorksheetCosts.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default WorksheetCosts;
