import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import Typography from "@material-ui/core/Typography";
import UserCost from "./UserCost";
import { yellow } from "@material-ui/core/colors";

class UserCosts extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("user-costs");

    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes["user-costs"],
        id: "user-costs",
        url: "user-costs",
        condition: user.can([1, 2])
          ? null
          : {
            id: "user_id",
            value: user.data.id
          },
        columns: [
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name"],
              //filter: [["unit", "=", 1]],
              sort: { "user.name": "asc" },
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          {
            id: "day",
            type: "unixDate",
            defaultValue: new Date().getTime() / 1000
          },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              search: ["project.name"],
              filter: [["project.active", "exact", true]],
              label: "name"
            },
            hidden: item => {
              if (!item.cost) {
                return true;
              }
              if (item.cost.move === true) {
                return true;
              }
              return false;
            }
          },
          {
            id: "cost_id",
            type: "select",
            datasource: {
              controller: "costs",
              objectField: "cost",
              valueField: "id",
              search: ["cost.name"],
              sort: { "cost.name": "asc" },
              filter: [
                ["show_at_cost", "exact", 1],
                ["min_role", "gte", user.data.role]
              ],
              label: (item, isCell) => {
                if (isCell && item) {
                  return item.name;
                }
                return (
                  item && (
                    <div>
                      {item.name}
                      {item.description && (
                        <Typography
                          component="div"
                          variant="inherit"
                          className="description"
                          style={{
                            fontSize: 11,
                            marginTop: -4,
                            lineHeight: "13px",
                            whiteSpace: "normal"
                          }}
                        >
                          {item.description}
                        </Typography>
                      )}
                    </div>
                  )
                );
              }
            }
          },
          { id: "amount", type: "currency", footer: "sum" },
          { id: "description" },
          {
            id: "person_id",
            type: "select",
            search: ["person.name"],
            datasource: {
              controller: "users",
              objectField: "person",
              valueField: "id",
              search: ["user.name"],
              sort: { "user.name": "asc" },
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            },
            hidden: item => {
              if (item.cost && item.cost.resource === 2) {
                return false;
              }
              return true;
            },
            label: item => {
              if (item.cost && item.cost.incoming === true) {
                return "Kitől";
              }
              return "Kinek";
            }
          },
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["vehicle.platenumber"],
              sort: { platenumber: "asc" },
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.platenumber : "";
              }
            },
            /*visible: item => {
              return false;
            },*/
            hidden: item => {
              if (item.cost && item.cost.resource === 1) {
                return false;
              }
              return true;
            }
          },
          {
            id: "counter",
            type: "integer",
            hidden: item => {
              if (item.cost && item.cost.counter_required) {
                return false;
              }
              return true;
            }
          },
          {
            id: "amount_count",
            type: "float",
            hidden: item => {
              if (item.cost && item.cost.amount_count_required) {
                return false;
              }
              return true;
            }
          },
          {
            id: "worksheet_id",
            //type: "te",
            cell: item =>
              item.original.worksheet_id ? "#" + item.original.worksheet_id : ""
          },
          {
            id: "invoice_number"
          },
          {
            id: "is_handled",
            type: "checkbox",
            hidden: item => !item.id || !user.can([1, 2])
            //cell: item => (item.accepted ? "Igen" : "")
          },
          {
            id: "accepted",
            type: "checkbox",
            hidden: item => !item.id || !user.can([1, 2])
            //cell: item => (item.accepted ? "Igen" : "")
          }
        ],
        fields: [
          { id: "user_id" },
          { id: "day" },
          { id: "cost_id" },
          { id: "project_id" },
          { id: "amount" },
          { id: "description" },
          { id: "person_id" },
          { id: "vehicle_id" },
          { id: "counter" },
          { id: "amount_count" },
          { id: "accepted" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormComponent={UserCost}
          ViewComponent={UserCost}
          FormFooter="CreatedUpdated"
          defaultSort={[{ id: "day", desc: true }]}
          fieldAccessHandler={(column, item) => {
            const access = user.accessField("user-costs", column.id);
            if (access) {
              if (column.id === "accepted" && user.can([1])) {
                return "w";
              }
              return item && (item.worksheet_id || item.accepted)
                ? "r"
                : access;
            }
            return access;
          }}
          inlineEdit={true}
          rowStyle={(item, isOdd) => {
            if (item.has_document && !item.is_handled && user.can([1, 2])) {
              return {
                backgroundColor: yellow[200]
              };
            }
          }}
          access={item => {
            if (item && (item.worksheet_id || item.last_open > item.day)) {
              return "r";
            }

            if (item && item.accepted) {
              if (user.can([1])) {
                return "w";
              } else {
                return "r";
              }
            }

            return this.state.access;
          }}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return (
                (item.cost ? item.cost.name : "Ismeretlen költség") +
                (item.worksheet_id ? " #" + item.worksheet_id : "")
              );
            } else {
              return "Új költség";
            }
          }}
        />
      </div>
    );
  }
}

UserCosts.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserCosts;
