import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
const costResources = [
  { value: 1, label: "Jármű" },
  { value: 2, label: "Felhasználó" }
];

const walletTypes = [
  { value: 1, label: "Céges pénztárca" },
  { value: 2, label: "Fizetés" }
];

const signTypes = [
  { value: 1, label: "Növelés" },
  { value: -1, label: "Csökkentés" }
];

class Costs extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("costs");

    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes.costs,
        id: "costs",
        url: "costs",
        columns: [
          { id: "name" },
          { id: "resource", type: "select", options: costResources },
          { id: "description", visible: false },
          {
            id: "pair_cost_id",
            type: "select",
            search: ["paircost.name"],
            datasource: {
              controller: "costs",
              objectField: "paircost",
              valueField: "id",
              search: ["cost.name"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          {
            id: "src_wallet",
            type: "select",
            options: walletTypes,
            visible: false
          },
          {
            id: "src_wallet_sign",
            type: "select",
            options: signTypes,
            visible: false
          },
          {
            id: "dest_wallet",
            type: "select",
            options: walletTypes,
            visible: false,
            hidden: item => {
              return item.resource !== 2;
            }
          },
          {
            id: "dest_wallet_sign",
            type: "select",
            options: signTypes,
            visible: false,
            hidden: item => {
              return item.resource !== 2;
            }
          },
          /*{
            id: "min_role",
            type: "select",
            options: roles,
            visible: false
          },*/
          {
            id: "min_role",
            type: "select",
            datasource: {
              controller: "roles",
              objectField: "role",
              valueField: "id",
              search: ["role.name"],
              label: "name",
              sort: { "role.name": "asc" }
            }
          },
          {
            id: "desc_required",
            cell: row => (row.value ? "Igen" : "Nem"),
            type: "checkbox"
          },
          {
            id: "counter_required",
            cell: row => (row.value ? "Igen" : "Nem"),
            type: "checkbox"
          },
          {
            id: "amount_count_required",
            //cell: row => (row.value ? "Igen" : "Nem"),
            type: "checkbox"
          },
          /* {
            id: "cash",
            cell: row => (row.value ? "Igen" : "Nem"),
            type: "checkbox"
          },*/
          { id: "show_at_worksheet", type: "checkbox", visible: false },
          { id: "show_at_cost", type: "checkbox", visible: false },
          { id: "show_only_at_project", type: "checkbox", visible: false },
          { id: "open", type: "checkbox", visible: false },
          { id: "hide_at_stat", type: "checkbox", visible: false },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("costs", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új költségtípus";
            }
          }}
        />
      </div>
    );
  }
}

Costs.propTypes = {
  user: PropTypes.object.isRequired
};

export default Costs;
