import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
//import "react-table/react-table.css";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid";
import FormComboboxField from "common/components/DataForm/FormComboboxField";
import FormTextField from "common/components/DataForm/FormTextField";
import FormFileField from "common/components/DataForm/FormFileField";
import FormJsonField from "common/components/DataForm/FormJsonField";
import FormTableField from "common/components/DataForm/FormTableField";
import FormTableField2 from "common/components/DataForm/FormTableField2";
import FormSelectField from "common/components/DataForm/FormSelectField";
import FormCheckboxField from "common/components/DataForm/FormCheckboxField";
import FormDateTimePickerField from "common/components/DataForm/FormDateTimePickerField";
import FormDateRangePickerField from "common/components/DataForm/FormDateRangePickerField";
import FormFooter from "common/components/DataForm/FormFooter";
import dataFormStyle from "common/assets/style/dataFormStyle";
import restHelper from "common/utils/restHelper";
import dateHelper from "common/utils/dateHelper";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Spacer from "common/components/Spacer";
import settings from "variables/settings";
import ModalConfirm from "common/components/Modals/ModalConfirm";
import { Fragment } from "react";
import { Typography, CircularProgress, Toolbar } from "@material-ui/core";
import strings from "variables/strings.json";
import { globalMessage } from "common/components/GlobalMessaging";
import { get } from "lodash";
/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DataForm extends React.Component {
  constructor(props) {
    super(props);

    let fieldMap = {};
    for (let i in props.definition.columns) {
      const key = props.definition.columns[i].id;
      fieldMap[key] = props.definition.columns[i];
    }

    this.state = {
      loading: false,
      error: {},
      fields: this.prepareFields(props.definition, fieldMap),
      isDirty: false,
      globalErrors: null,
      showGlobalErrors: false,
      fieldMap: fieldMap
    };

    this.state.item = this.convertValues(this.addDefaultValues(props.item));

    //Eltároljuk az eredeti értékeket, hogy tudjuk mi változott

    this.state.originalItem =
      props.item && props.item[props.primaryKey]
        ? Object.assign({}, this.convertValues(props.item))
        : {};

    this.modal = null;
  }

  isDirty = () => {
    //console.log(this.state);
    for (let i in this.state.item) {
      const newValue = this.state.item[i];
      const originalValue = this.state.originalItem[i];
      const col = this.state.fieldMap[i];
      if (col && col.access !== "w") {
        continue;
      }
      //null, undefined, "" egyenlőnek tekintendő a hasonlításkor
      const newValueCompare =
        newValue === undefined || newValue === null || newValue === ""
          ? null
          : newValue;
      const originalValueCompare =
        originalValue === undefined ||
        originalValue === null ||
        originalValue === ""
          ? null
          : originalValue;

      if (
        JSON.stringify(newValueCompare) !== JSON.stringify(originalValueCompare)
      ) {
        return true;
      }
    }

    return false;
  };

  changedValues = () => {
    let changedValues = {};
    for (let i in this.state.item) {
      const newValue = this.state.item[i];
      const originalValue = this.state.originalItem[i];

      if (newValue !== originalValue || typeof newValue === "object") {
        //if (typeof newValue !== "object") {
        changedValues[i] = newValue;
        //}
      }
    }

    return changedValues;
  };

  addDefaultValues = item => {
    let defaultValues = {};
    for (let i in this.props.definition.columns) {
      let c = this.props.definition.columns[i];
      if (c.defaultValue || c.defaultValue === false || c.defaultValue === 0) {
        if (typeof c.defaultValue === "function") {
          defaultValues[c.id] = c.defaultValue(item);
        } else {
          defaultValues[c.id] = c.defaultValue;
        }
      }
    }
    return Object.assign({}, defaultValues, item);
  };

  prepareFields(definition, fieldMap) {
    if (!definition.columns) {
      return definition.fields;
    }

    if (!definition.fields) {
      return definition.columns;
    }
    let fields = [];
    for (let i in definition.fields) {
      const key = definition.fields[i].id;
      if (fieldMap[key]) {
        fields.push(Object.assign(fieldMap[key], definition.fields[i]));
      } else {
        fields.push(definition.fields[i]);
      }
    }

    return fields;
  }

  convertValues = (values, deconvert) => {
    //const { fields, columns } = this.props.definition;
    //const cols = fields ? fields : columns;

    let converted = Object.assign({}, values);
    for (let i in this.state.fields) {
      let col = this.state.fields[i];
      converted[col.id] = this.convertValue(
        col.type,
        converted[col.id],
        deconvert
      );
    }
    return converted;
  };

  /**
   * A Egyedi cella objktum vagy a cellában megejelnő érték konvertálása
   * @param {string} convert Konvertálási típus
   * @param {string} type  cella típus
   */
  convertValue = (type, value, deconvert) => {
    let v = value;
    switch (type) {
      case "unixDate":
        v = deconvert
          ? dateHelper.dateToUnix(value)
          : dateHelper.unixToDate(value);
        break;
      case "unixDateTime":
        v = deconvert
          ? dateHelper.dateToUnix(value)
          : dateHelper.unixToDateTime(value);
        break;
      case "unixTime":
        v = deconvert
          ? dateHelper.timeToUnix(value)
          : dateHelper.unixToTime(value);
        break;
      default:
        break;
    }
    return v;
  };

  /**
   * Frissítjük az értékeket
   * @param {Object} nextProps Új tulajdonságok
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    //Eltároljuk az eredeti értékeket, hogy tudjuk mi változott
    const { primaryKey } = this.props;

    let originalItem = nextProps.item[primaryKey]
      ? Object.assign({}, this.convertValues(nextProps.item))
      : {};

    this.setState({
      item: this.convertValues(this.addDefaultValues(nextProps.item)),
      originalItem: originalItem
    });
  }

  /**
   * Form mező változások lekezelése
   */
  onChange = values => {
    //console.log("values", values);
    let item = Object.assign({}, this.state.item);
    let changedHandlers = [];
    for (let id in values) {
      const value = values[id];

      item[id] = value
        ? value === ""
          ? null
          : value
        : value === false
        ? false
        : value === 0
        ? 0
        : null;

      if (this.state.fieldMap[id] && this.state.fieldMap[id].changeValues) {
        //this.state.fieldMap[id].changeValues(this.convertValues(item, true));
        changedHandlers.push(id);
      }
    }

    changedHandlers.forEach(element => {
      this.state.fieldMap[element].changeValues(item);
    });

    this.setState({ item: item });
  };

  /**
   * Szerkesztés visszavonása
   */
  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onCancelChanges = () => {
    this.setState({
      item: Object.assign({}, this.state.originalItem),
      error: {}
    });
  };

  addValidationProps = item => {
    const { columns } = this.props.definition;
    let result = Object.assign({}, item);
    columns.forEach(column => {
      if (column.validationProp) {
        result[column.validationProp] = true;
      }
    });

    return result;
  };

  showSavePopupMessage = message => {
    this.modal
      .open(
        "Figyelmeztetés",
        message,
        null, //this.state.file.file_name,
        "Ok",
        null
      )
      .then(() => {
        this.modal.close();
      });
  };

  /**
   * Adatok mentése az adatbázisba
   */
  onSave = forceCloneSave => {
    const { definition, isRedux, primaryKey } = this.props;

    //const item = this.convertValues(this.changedValues(this.state.item), true);
    const item = this.convertValues(this.state.item, true);
    const isNew = this.state.item[primaryKey] ? false : true;
    const create =
      isRedux && this.props.createR ? this.props.createR : restHelper.create;
    const update =
      isRedux && this.props.updateR ? this.props.updateR : restHelper.update;
    //controller, id, item, params, popupCallback
    this.setState({ loading: true });
    if (isNew) {
      create(
        definition.url,
        this.addValidationProps(item),
        undefined,
        this.showSavePopupMessage
      )
        .then(savedItem => {
          this.setState(
            {
              error: {},
              globalErrors: null,
              showGlobalErrors: false,
              loading: false,
              originalItem: Array.isArray(savedItem)
                ? this.state.item
                : this.convertValues(savedItem),
              item: Array.isArray(savedItem)
                ? this.state.item
                : this.convertValues(savedItem)
            },
            () => {
              globalMessage.success("Sikeres mentés.");
            }
          );
          if (this.props.afterSave) {
            this.props.afterSave(savedItem, true);
          }
        })
        .catch(error => {
          const globalErrors = this.globalErrors(error);

          this.setState({
            error: error,
            loading: false,
            globalErrors: globalErrors,
            showGlobalErrors: globalErrors ? true : false
          });
        });
    } else {
      update(
        definition.url,
        this.state.item[primaryKey],
        this.addValidationProps(item),
        forceCloneSave === true ? { forceCloneSave: 1 } : undefined,
        this.showSavePopupMessage
      )
        .then(savedItem => {
          this.setState(
            {
              error: {},
              loading: false,
              globalErrors: null,
              showGlobalErrors: false,
              originalItem: this.convertValues(savedItem),
              item: this.convertValues(savedItem)
            },
            () => {
              globalMessage.success("Sikeres mentés.");
            }
          );
          if (this.props.afterSave) {
            this.props.afterSave(savedItem);
          }
        })
        .catch(error => {
          const globalErrors = this.globalErrors(error);
          this.setState({
            error: error,
            loading: false,
            globalErrors: globalErrors,
            showGlobalErrors: globalErrors ? true : false
          });
        });
    }
  };

  errorIsShown = errorKey => {
    const { fields, item } = this.state;
    for (let i in fields) {
      const field = fields[i];
      if (field.id === errorKey) {
        const h =
          field.hidden && typeof field.hidden === "function"
            ? field.hidden(item)
            : field.hidden;
        if (!h) {
          return true;
        }
      }

      if (field.validatesBy && field.validatesBy.indexOf(errorKey) >= 0) {
        const h =
          field.hidden && typeof field.hidden === "function"
            ? field.hidden(item)
            : field.hidden;
        if (!h) {
          return true;
        }
      }
    }
  };

  globalErrors = error => {
    if (!error) {
      return "A manóba. :(";
    }
    if (error.status === "error") {
      return error.error;
    }
    const { fields } = this.state;
    let fieldMap = {};
    for (let i in fields) {
      const key = fields[i].id;
      fieldMap[key] = fields[i];
    }

    let errors = [];
    for (let i in error) {
      const e = error[i];
      if (!fieldMap[i]) {
        errors.push(e.join(", "));
      } else if (/*fieldMap[i].hidden*/ !this.errorIsShown(i)) {
        errors.push(e.join(", "));
      }
    }
    return errors.join(", ");
  };

  /**
   * A definícióban megadott editor tulajdonság függvényében
   * az adott mező szereksztőjének kiválasztása (text, combobox, select, ...)
   */
  getField = (column, key, item, error) => {
    const { classes, theme, access, primaryKey } = this.props;
    const { attributes } = this.props.definition;
    //console.log(column.id, column.access, access);
    let colProp = column.name;
    if (colProp) {
      colProp = typeof colProp === "function" ? colProp(item) : colProp;
    }
    const name =
      colProp ||
      (attributes && attributes[column.id] ? attributes[column.id] : column.id);
    const helperText =
      attributes && attributes[column.id + "_helper_text"]
        ? attributes[column.id + "_helper_text"]
        : null;

    const isNew = this.state.item[primaryKey] ? false : true;
    //Szerkesztés globális letiltása
    //Ha csak olvasási jog van a formra
    if (access === "r") {
      column.access = "r";
    }

    //Ha nincs szerkesztési jog és nem újfelvitel van
    if (access.indexOf("w") < 0 && !isNew) {
      column.access = "r";
    }

    if (access.indexOf("a") >= 0 && isNew) {
      column.access = "w";
    }

    //Ha nincs felviteli jog és újfelvitel van
    if (access.indexOf("a") < 0 && isNew) {
      column.access = "r";
    }
    if (column.readOnly && typeof column.readOnly === "function") {
      if (column.readOnly(item)) {
        //console.log("column.access", String(column.access), item);
        column.access = "r";
      }
    } else if (column.readOnly) {
      column.access = "r";
    }

    let required = undefined;

    if (column.required && typeof column.required === "function") {
      required = column.required(item);
    } else {
      required = column.required;
    }

    switch (column.type) {
      case "combobox":
        return (
          <FormComboboxField
            id={column.id}
            name={name}
            options={column.options}
            value={item[column.id] === null ? "" : item[column.id]}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            item={item}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "select":
        return (
          <FormSelectField
            id={column.id}
            name={name}
            options={column.options}
            datasource={column.datasource}
            value={item[column.id] === null ? "" : item[column.id]}
            column={column}
            isDeleted={item.deleted_at ? true : false}
            currentValue={
              column.datasource && column.datasource.objectField
                ? typeof column.datasource.objectField === "string"
                  ? item[column.datasource.objectField]
                  : column.datasource.objectField
                : null
            }
            error={error}
            onChange={this.onChange}
            onSave={this.onSave}
            classes={classes}
            theme={theme}
            item={item}
            dirty={
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "checkbox":
        return (
          <FormCheckboxField
            id={column.id}
            name={name}
            //a={console.log("CHECKBOX", name, item[column.id])}
            //options={column.options}
            //datasource={column.datasource}
            value={item[column.id]}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            item={item}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "unixDate":
        var v = item[column.id];
        if (column.id.indexOf(".") >= 0) {
          v = parseInt(get(item, column.id.split(".")));
          v = dateHelper.unixToDate(v);
        }
        return (
          <FormDateTimePickerField
            id={column.id}
            name={name}
            //options={column.options}
            //datasource={column.datasource}
            value={v === null ? "" : v}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            type={column.type}
            todayHotButton={column.todayHotButton}
            item={item}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "unixDateTime":
        var v1 = item[column.id];
        if (column.id.indexOf(".") >= 0) {
          v = parseInt(get(item, column.id.split(".")));
          v = dateHelper.unixToDateTime(v1);
        }
        return (
          <FormDateTimePickerField
            id={column.id}
            name={name}
            //options={column.options}
            //datasource={column.datasource}
            value={v1 === null ? "" : v1}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            type={column.type}
            todayHotButton={column.todayHotButton}
            item={item}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "unixDateRange":
        //console.log(item, this.state.originalItem);
        var value = {
          start: item[column.fields.start],
          end: item[column.fields.end]
        };
        return (
          <FormDateRangePickerField
            id={column.id}
            name={name}
            fields={column.fields}
            //options={column.options}
            //datasource={column.datasource}
            value={value.start ? value : null}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            type={column.type}
            item={item}
            initialYear={column.initialYear}
            initialMonth={column.initialMonth}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "file":
        return (
          <FormFileField
            id={column.id}
            name={name}
            helperText={helperText}
            value={item[column.id] === null ? "" : item[column.id]}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            format={column.format}
            column={column}
            type={column.type}
            item={item}
            multiline={column.multiline}
            isNew={isNew}
            rows={column.rows}
            placeholder={column.placeholder}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
      case "json":
        return (
          <FormJsonField
            id={column.id}
            name={name}
            helperText={helperText}
            value={item[column.id] === null ? "" : item[column.id]}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            format={column.format}
            type={column.type}
            item={item}
            multiline={column.multiline}
            rows={column.rows}
            height={column.height}
            placeholder={column.placeholder}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              JSON.stringify(this.state.item[column.id]) !==
                JSON.stringify(this.state.originalItem[column.id])
            }
            required={required}
          />
        );
      case "table":
        return (
          <FormTableField
            id={column.id}
            name={name}
            helperText={helperText}
            value={item[column.id] === null ? "" : item[column.id]}
            error={error}
            onChange={this.onChange}
            //classes={classes}
            //theme={theme}
            fullWidth
            format={column.format}
            type={column.type}
            item={item}
            multiline={column.multiline}
            columns={column.columns}
            height={column.height}
            placeholder={column.placeholder}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              JSON.stringify(this.state.item[column.id]) !==
                JSON.stringify(this.state.originalItem[column.id])
            }
            required={required}
          />
        );
      case "table2":
        return (
          <FormTableField2
            id={column.id}
            name={name}
            helperText={helperText}
            value={item[column.id] === null ? "" : item[column.id]}
            error={error}
            onChange={evt => {
              //console.log(a, b);
              this.onChange({ [column.id]: evt.target.value });
            }}
            //classes={classes}
            //theme={theme}
            fullWidth
            format={column.format}
            type={column.type}
            item={item}
            multiline={column.multiline}
            columns={column.columns}
            height={column.height}
            placeholder={column.placeholder}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              JSON.stringify(this.state.item[column.id]) !==
                JSON.stringify(this.state.originalItem[column.id])
            }
            required={required}
          />
        );
      case "password":
      case "text":
      default:
        var v = get(item, column.id.split("."));
        /* console.log(
          "item.policy.premium_amount",
          item.policy.premium_amount,
          column.id,
          v
        );*/
        return (
          <FormTextField
            id={column.id}
            name={name}
            helperText={helperText}
            //value={item[column.id] === null ? "" : item[column.id]}
            value={v === null ? "" : v}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            format={column.format}
            currencyType={column.currencyType}
            type={column.type}
            item={item}
            multiline={column.multiline}
            rows={column.rows}
            placeholder={column.placeholder}
            access={item.deleted_at ? "r" : column.access}
            dirty={
              !(
                typeof this.state.originalItem[column.id] === "undefined" &&
                !this.state.item[column.id]
              ) &&
              this.state.item[column.id] !== this.state.originalItem[column.id]
            }
            required={required}
          />
        );
    }
  };

  editButtonIsVisible = item => {
    const { access, primaryKey } = this.props;
    const isNew = this.state.item[primaryKey] ? false : true;
    if (access.indexOf("w") >= 0 && !isNew) {
      return true;
    }
    if (access.indexOf("a") >= 0 && isNew) {
      return true;
    }
    return false;
  };

  render() {
    const {
      definition,
      Footer,
      classes,
      fieldsInRow,
      fieldAccessHandler,
      bottomButtons,
      grid,
      hideSaveButton
      //access
    } = this.props;
    const {
      item,
      error,
      fields,
      globalErrors,
      showGlobalErrors,
      loading
    } = this.state;
    let FooterComp = null;
    if (Footer && typeof Footer === "string") {
      switch (Footer) {
        case "CreatedUpdated":
          FooterComp = FormFooter;
          break;
        default:
          break;
      }
    } else if (Footer) {
      FooterComp = Footer;
    }

    if (this.props.error) {
      return (
        <div className={classes.formContainer}>
          <div className={classes.error}>{this.props.error}</div>
        </div>
      );
    }
    let tempHeader = null;

    const buttons = bottomButtons
      ? bottomButtons.filter(button => {
          if (button.access && !this.props.user.accessFunction(button.access)) {
            return false;
          }
          if (button.visible && typeof button.visible === "function") {
            return button.visible(item /*, { grid, form: this }*/);
          }
          return true;
        })
      : null;

    const hideSaveBtn =
      typeof hideSaveButton === "function"
        ? hideSaveButton(item, this.props.user)
        : hideSaveButton || false;

    return (
      <div className={classes.formContainer}>
        <Grid container={true} spacing={2}>
          {this.props.TopHeaderComponent && (
            <Grid item xs={12}>
              <this.props.TopHeaderComponent
                item={item}
                parentItem={this.props.parentItem}
              />
            </Grid>
          )}
          {fields
            .filter(obj => {
              if (typeof obj.hidden === "function") {
                if (obj.hidden(item, true) === true) {
                  return false;
                }
              } else if (obj.hidden === true) {
                return false;
              }
              if (fieldAccessHandler) {
                obj.access = fieldAccessHandler(obj, item);
                return obj.access === "r" || obj.access === "w";
              } else {
                return true;
              }
            })
            .map((column, key) => {
              const err =
                error && error[column.id] && Array.isArray(error[column.id])
                  ? error[column.id].join(", ")
                  : null;
              const needHeader = column.header && tempHeader !== column.header;
              const headerTitle =
                (column.header &&
                  strings.headers &&
                  strings.headers[definition.url] &&
                  strings.headers[definition.url][column.id]) ||
                "Missing header title";
              tempHeader = column.header;
              return (
                <Fragment key={key}>
                  {needHeader && (
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ fontWeight: 400 }}>
                        {headerTitle}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    lg={
                      column.size
                        ? column.size
                        : fieldsInRow
                          ? 12 / fieldsInRow
                          : 4
                    }
                  >
                    {this.getField(column, key, item, err)}
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
        {FooterComp ? (
          <FooterComp item={item} typoClassName={classes.footerTypo} />
        ) : (
          ""
        )}
        {/*<Button color="secondary" onClick={this.onCancel}>
          Vissza
        </Button>*/}
        {this.editButtonIsVisible(item) && (
          <Toolbar disableGutters>
            {!hideSaveBtn && (
              <Button
                //className={classes.saveButton}
                color={settings.actionColor}
                variant="contained"
                onClick={this.onSave}
                disabled={!this.isDirty() || loading}
                endIcon={loading ? <CircularProgress size={16} /> : undefined}
              >
                Mentés
              </Button>
            )}
            {buttons &&
              buttons.length > 0 &&
              buttons.map((button, buttonIndex) => {
                const btProps =
                  typeof button.buttonProps === "function"
                    ? button.buttonProps(this) || {
                      variant: button.variant
                    }
                    : button.buttonProps || { variant: button.variant };

                const disabled = this.isDirty() ? true : btProps.disabled;
                return (
                  <Fragment key={buttonIndex}>
                    <Spacer w={1} />
                    <Button
                      variant={button.variant}
                      startIcon={<button.Icon />}
                      color={button.color ? button.color : "inherit"}
                      //className={classes.button}
                      aria-label={button.tooltip}
                      onClick={
                        button.onClick
                          ? evt =>
                              button.onClick(
                              evt,
                                { data: item, form: this, grid: grid },
                                button.value
                              )
                          : button.modalComponent
                          ? () =>
                              grid.setState({
                                [`modal${button.id}`]: true,
                                [`modal_inactive${button.id}`]: false
                              })
                          : undefined
                      }
                      component={button.component}
                      href={button.href}
                      to={button.to}
                      {...btProps}
                      disabled={disabled}
                    >
                      {button.tooltip}
                    </Button>
                  </Fragment>
                );
              })}
            <Spacer right />
            {!hideSaveBtn && item.id && (
              <Button
                //className={classes.saveButton}
                color="default"
                onClick={this.onCancelChanges}
                disabled={!this.isDirty() || loading}
              >
                Módosítások elvetése
              </Button>
            )}
          </Toolbar>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={showGlobalErrors}
          autoHideDuration={6000}
          onClose={() => this.setState({ showGlobalErrors: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarRoot
          }}
          message={<span id="message-id">{globalErrors}</span>}
          action={[
            /*<Button
                key="undo"
                color="secondary"
                size="small"
                onClick={() => this.setState({ showGlobalErrors: false })}
              >
                UNDO
              </Button>,*/
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.setState({ showGlobalErrors: false })}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
        <ModalConfirm onRef={ref => (this.modal = ref)} disabelCancel />
      </div>
    );
  }
}

DataForm.defaultProps = {
  primaryKey: "id"
};
DataForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired,
  fieldsInRow: PropTypes.number,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  afterSave: PropTypes.func,
  Footer: PropTypes.any,
  fieldAccessHandler: PropTypes.func,
  error: PropTypes.string,
  primaryKey: PropTypes.string,
  isRedux: PropTypes.bool,
  bottomButtons: PropTypes.array,
  hideSaveButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};
//export default DataForm;
export default withStyles(dataFormStyle, { withTheme: true })(DataForm);
