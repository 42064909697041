import React from "react";
import strings from "variables/strings.json";
import DataGridForm from "common/components/DataGrid/DataGridForm";
import WorksheetUsers from "views/Worksheets/WorksheetUsers";
import WorksheetTasks from "views/Worksheets/WorksheetTasks";
import WorksheetCosts from "views/Worksheets/WorksheetCosts";
import WorksheetVehicles from "views/Worksheets/WorksheetVehicles";
import WorksheetPreview from "views/Worksheets/WorksheetPreview";

const Worksheet = props => {
  let previewTitle = strings.menu.worksheets.previewTitle;
  if (
    props.item.status === 1 &&
    !props.user.accessFunction("handle_sended_worksheets")
  ) {
    previewTitle = strings.menu.worksheets.closeTitle;
  }
  if (
    props.item.status === 2 &&
    props.user.accessFunction("worksheet_accept")
  ) {
    previewTitle = strings.menu.worksheets.acceptTitle;
  }

  return (
    <DataGridForm
      {...props}
      tabs={{
        id: "worksheets",
        documents: false,
        histories: false,
        items: {
          users: {
            itemKey: "worksheet_id",
            constroller: "worksheet-users",
            component: WorksheetUsers,
            title: strings.menu.worksheets.usersTitle
            //badgeContent: "item_count"
          },
          tasks: {
            itemKey: "worksheet_id",
            component: WorksheetTasks,
            constroller: "worksheet-tasks",
            title: strings.menu.worksheets.tasksTitle
            //badgeContent: "item_count"
          },
          costs: {
            itemKey: "worksheet_id",
            component: WorksheetCosts,
            constroller: "user-costs",
            title: strings.menu.worksheets.costsTitle
            //badgeContent: "item_count"
          },
          vehicles: {
            itemKey: "worksheet_id",
            component: WorksheetVehicles,
            constroller: "worksheet-vehicles",
            title: strings.menu.worksheets.vehiclesTitle
            //badgeContent: "item_count"
          },
          preview: {
            itemKey: "worksheet_id",
            component: WorksheetPreview,
            title: previewTitle,
            allowed: true,
            componentProps: {
              afterSave: props.afterSave
            }
            //badgeContent: "item_count"
          }
        }
      }}
    />
  );
};

export default Worksheet;
