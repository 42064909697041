import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import loginPageStyle from "common/assets/style/loginPageStyle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import restHelper from "common/utils/restHelper";
import logo from "assets/img/logo.svg";
//import LogoIcon from "assets/LogoIcon.jsx";
import strings from "variables/strings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//const onAutoFillStart = (el) => { console.log(el);};
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      shouldShrink: false,
      error: {}
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.email && this.email.matches(":-webkit-autofill")) {
        this.setState({ shouldShrink: true });
      } else {
        setTimeout(() => {
          if (this.email && this.email.matches(":-webkit-autofill")) {
            this.setState({ shouldShrink: true });
          }
        }, 500);
      }
    }, 500);
  }

  error = attribute => {
    const { error } = this.state;
    if (error[attribute]) {
      const errors = error[attribute];
      if (Array.isArray(errors)) {
        return errors.join(", ");
      } else {
        return errors;
      }
    }

    return false;
  };

  handleChange = name => event => {
    //console.log(name, event.target.value);
    this.setState({
      [name]: event.target.value,
      shouldShrink: false
    });
  };

  handleLogin = () => {
    this.props
      .login("users", this.state.email, this.state.password)
      .catch(error => {
        this.setState({ error: error.data });
      });
  };

  handleInputKeypress = evt => {
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      this.handleLogin();
    }
  };

  render() {
    const { classes /*, theme*/ } = this.props;
    //console.log(this.props.theme);
    let inputLabelProps = {};
    if (this.state.shouldShrink) {
      inputLabelProps.shrink = true;
    }
    return (
      <Grid container={true} justify="center" className={classes.container}>
        <Grid item={true} xs={12} md={4}>
          <div className={classes.headerContainer}>
            <img
              alt={strings.brand}
              title={strings.brand}
              src={logo}
              className={classes.logo}
            />
            <br />
            <Typography variant="h6" className={classes.appName}>
              {strings.appName}
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h5">
              Belépés
            </Typography>
            <Typography variant="body2" className={classes.message}>
              Adja meg adatait a belépéshez.
            </Typography>
            <form noValidate autoComplete="off">
              <TextField
                inputRef={ref => (this.email = ref)}
                id="email"
                label="Felhasználónév"
                helperText={this.error("email") || ""}
                error={this.error("email") ? true : false}
                InputLabelProps={inputLabelProps}
                className={classes.textField}
                fullWidth
                value={this.state.email}
                autoComplete="off"
                onChange={this.handleChange("email")}
                onKeyPress={this.handleInputKeypress}
                margin="normal"
                variant="outlined"
              />
              <TextField
                inputRef={ref => (this.password = ref)}
                id="password"
                label="Jelszó"
                helperText={this.error("password") || ""}
                error={this.error("password") ? true : false}
                InputLabelProps={inputLabelProps}
                className={classes.textField}
                fullWidth
                value={this.state.password}
                autoComplete="off"
                onChange={this.handleChange("password")}
                onKeyPress={this.handleInputKeypress}
                type="password"
                //autoComplete="current-password"
                margin="normal"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleLogin}
              >
                Belépés
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators(restHelper, dispatch);

export default withStyles(loginPageStyle, { withTheme: true })(
  connect(
    null,
    mapDispatchToProps
  )(LoginPage)
);
