import React from "react";
import ProjectTasks from "views/Projects/ProjectTasks";
import ProjectCosts from "views/Projects/ProjectCosts";
import DataGridForm from "common/components/DataGrid/DataGridForm";

const Project = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "projects",
      documents: true,
      histories: false,
      items: {
        "project-tasks": {
          itemKey: "user_id",
          component: ProjectTasks
          //badgeContent: "item_count"
        },
        "project-costs": {
          itemKey: "user_id",
          component: ProjectCosts
          //badgeContent: "item_count"
        }
      }
    }}
  />
);

export default Project;
