import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import Spacer from "common/components/Spacer";
import dateHelper from "common/utils/dateHelper";
import restHelper from "common/utils/restHelper";
import React from "react";

class MyHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: 1,
      items: [],
      start_date: null,
      end_date: null,
      month: null,
      total: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleIntervalChange = interval => {
    this.setState({ interval }, () => {
      this.fetchData();
    });
  };

  fetchData = async () => {
    const { interval } = this.state;
    const data = await restHelper.index("reports/my-hours", { interval });
    this.setState(data);
    //console.log(data);
  };

  render() {
    const { classes, theme } = this.props;
    const { interval, items, month, total } = this.state;
    console.log(this.state);
    return (
      <Grid container={true} spacing={4}>
        <Tabs
          value={interval}
          onChange={(evt, value) => this.handleIntervalChange(value)}
        >
          <Tab label="Előző hónap" value={2} />
          <Tab label="Aktuális hónap" value={1} />
        </Tabs>
        <Grid item={true} xs={12}>
          <Typography variant="h4">{month}</Typography>
          <Spacer h={1} />
          <Typography variant="h5">{total} óra</Typography>
          <Spacer h={1} />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nap</TableCell>
                <TableCell>Munkaidő</TableCell>
                <TableCell>Óra</TableCell>
                <TableCell>Projekt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{dateHelper.unixToDate(item.day)}</TableCell>
                    <TableCell>
                      {dateHelper.unixToTime(item.start_hour)} -{" "}
                      {dateHelper.unixToTime(item.end_hour)}
                    </TableCell>
                    <TableCell>
                      {Math.round(item.work_hours * 100) / 100}
                    </TableCell>
                    <TableCell>{item.project}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

MyHours.propTypes = {};

export default withStyles(dataFormStyle, { withTheme: true })(MyHours);
