window.isdev = window.location.port === "3001" ? true : false;
const subFolder = "";
const settings = {
  subFolder: subFolder,
  actionColor: "primary",
  relesesSlug: "/administration/releases",
  profileSlug: "/profile",
  settingsSlug: "/settings",
  favourites: "db",
  api: {
    url: window.isdev
      ? "http://localhost:9001" + subFolder + "/api"
      : subFolder + "/api"
  },
  site: {
    url: window.isdev ? "http://localhost:9001" : ""
  }
};

export default settings;
